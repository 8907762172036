import { useTranslation } from "react-i18next";
import { formatNumber, formatPercent } from "modules/reporting/lib/formatters";
import { Table } from "shared/components/Table";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { ReportTablePlaceholder } from "modules/reporting/components/ReportTablePlaceholder";
import { TableColumn } from "shared/components/Table/types/TableColumn";
import { Pagination } from "features/Pagination";
import { BacklogByAssigneeTableData } from "./types";
import { PaginationData } from "types/Response";

const reportKey = ReportKey.BacklogByAssignee;

interface BacklogByAssigneeReportTableProps {
  data?: BacklogByAssigneeTableData[];
  paginationData?: PaginationData;
  isFetching: boolean;
}

export const BacklogByAssigneeReportTable = ({
  data,
  paginationData,
  isFetching,
}: BacklogByAssigneeReportTableProps) => {
  const { t } = useTranslation();
  const columns = useTableColumns();

  if (!isFetching && !data?.length) {
    return <ReportTablePlaceholder />;
  }

  const sortData = (data: BacklogByAssigneeTableData[] | undefined) => {
    if (!data) {
      return [];
    }
    return [...data].sort((a, b) => b.active - a.active);
  };
  const sortedData = sortData(data);
  return (
    <>
      <Table
        columns={columns}
        rows={sortedData}
        getRowKey={(row) => row.assignee}
        showSkeleton={isFetching}
        notFound={!isFetching && !data}
      />
      {paginationData && (
        <Pagination
          paginationData={paginationData}
          label={t(`reporting.${reportKey}.paginationLabel`) as string}
        />
      )}
    </>
  );
};

function useTableColumns(): TableColumn<BacklogByAssigneeTableData>[] {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  return [
    {
      field: "assignee",
      name: t(`reporting.${reportKey}.columns.assignee`),
      minWidth: 200,
    },
    {
      field: "active",
      name: t(`reporting.${reportKey}.columns.active`),
      minWidth: 120,
      type: "number",
      formatValue: (val: number) => formatNumber(val, language),
    },
    {
      field: "overdue",
      name: t(`reporting.${reportKey}.columns.overdue`),
      minWidth: 120,
      renderCell: (row) =>
        `${formatNumber(row.overdue, language)} (${formatPercent(
          row.overdue_percentage,
          language
        )})`,
    },
    {
      field: "reactive",
      name: t(`reporting.${reportKey}.columns.reactive`),
      minWidth: 120,
      renderCell: (row) =>
        `${formatNumber(row.reactive, language)} (${formatPercent(
          row.reactive_percentage,
          language
        )})`,
    },
    {
      field: "preventive",
      name: t(`reporting.${reportKey}.columns.preventive`),
      minWidth: 120,
      renderCell: (row) =>
        `${formatNumber(row.preventive, language)} (${formatPercent(
          row.preventive_percentage,
          language
        )})`,
    },
  ];
}
