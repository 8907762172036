import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { NoData } from "modules/reporting/components/NoData";
import OverdueIcon from "modules/reporting/assets/overdue-icon.svg?react";
import { InlineProgressBar } from "./InlineProgressBar";
import { useWidgetData } from "modules/reporting/lib/useWidgetData";
import Skeleton from "react-loading-skeleton";
import { times } from "lodash";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { reportPeriodCurrent } from "modules/reporting/lib/reportPeriodOptions";

interface BacklogByAssigneeReportWidgetData {
  chart_data: RowData[];
  other_data: {
    active?: number;
    overdue?: number;
  };
}

interface RowData {
  assignee: string;
  active: number;
  overdue: number;
}

export const BacklogByAssigneeReportWidget = () => {
  const { t } = useTranslation();

  const MAX_DISPLAYED_RECORDS = 10;
  const { data, isFetching } = useWidgetData<BacklogByAssigneeReportWidgetData>(
    ReportKey.BacklogByAssignee,
    reportPeriodCurrent
  );

  if (isFetching) {
    return <WidgetSkeleton />;
  } else if (!data?.chart_data) {
    return <NoData />;
  }

  const clone_data = [...data.chart_data];
  const display_items = clone_data
    .sort((a, b) => b.active - a.active)
    .slice(0, MAX_DISPLAYED_RECORDS);

  // Get the largest value in the "active" and "overdue" columns
  // All InlineProgressBar sizes will be relative to this
  const maxValue = data?.chart_data?.reduce(
    (previousValue: any, currentValue: any) =>
      Math.max(previousValue, currentValue.active, currentValue.overdue),
    0
  );

  const { active, overdue } = data.other_data;
  return (
    <Wrapper>
      <Stats>
        {typeof active === "number" && (
          <span>
            {t(`reporting.${ReportKey.BacklogByAssignee}.active`, {
              count: active,
            })}
          </span>
        )}
        {typeof overdue === "number" && (
          <span>
            {t(`reporting.${ReportKey.BacklogByAssignee}.overdue`, {
              count: overdue,
            })}
          </span>
        )}
      </Stats>
      <Table>
        <TableHeader />
        <tbody style={{ display: "contents" }}>
          {display_items.map((row) => (
            <tr key={row.assignee} style={{ display: "contents" }}>
              <NameCell>{row.assignee}</NameCell>
              <Cell style={{ justifyContent: "end" }}>{row.active}</Cell>
              <Cell>
                <InlineProgressBar
                  color={"var(--color_primary)"}
                  value={row.active}
                  maxValue={maxValue}
                />
              </Cell>
              <Cell>
                {!!row.overdue && (
                  <OverdueCount>
                    <OverdueIcon />
                    <span>{row.overdue}</span>
                  </OverdueCount>
                )}
              </Cell>
              <Cell>
                <InlineProgressBar
                  color={"var(--color_grey400)"}
                  value={row.overdue}
                  maxValue={maxValue}
                />
              </Cell>
            </tr>
          ))}
        </tbody>
      </Table>
    </Wrapper>
  );
};

const WidgetSkeleton = () => {
  return (
    <Wrapper>
      <Stats>
        <Skeleton height="1em" width="15ch" />
        <Skeleton height="1em" width="15ch" />
      </Stats>
      <Table>
        <TableHeader />
        <tbody style={{ display: "contents" }}>
          {times(10, (index) => (
            <tr key={index} style={{ display: "contents" }}>
              <NameCell>
                <Skeleton height="1em" width="12ch" />
              </NameCell>
              <Cell style={{ justifyContent: "end" }}>
                <Skeleton height="1em" width="3ch" />
              </Cell>
              <Cell />
              <Cell style={{ justifyContent: "end" }}>
                <Skeleton height="1em" width="4ch" />
              </Cell>
              <Cell />
            </tr>
          ))}
        </tbody>
      </Table>
    </Wrapper>
  );
};

const TableHeader = () => {
  const { t } = useTranslation();
  return (
    <thead style={{ display: "contents" }}>
      <tr style={{ display: "contents" }}>
        <ColumnHeader>
          {t(`reporting.${ReportKey.BacklogByAssignee}.columns.assignee`)}
        </ColumnHeader>
        <ColumnHeader style={{ gridColumn: "span 2" }}>
          {t(`reporting.${ReportKey.BacklogByAssignee}.columns.active`)}
        </ColumnHeader>
        <ColumnHeader style={{ gridColumn: "span 2" }}>
          {t(`reporting.${ReportKey.BacklogByAssignee}.columns.overdue`)}
        </ColumnHeader>
      </tr>
    </thead>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
`;

const Stats = styled.div`
  display: flex;
  gap: 24px;
  padding-bottom: 16px;

  color: var(--color_dark_blue);
  font-size: 14px;
  font-weight: 600;
`;

const Table = styled.table`
  display: grid;
  grid-template-columns: 1fr auto 2fr auto 2fr;
  align-items: stretch;
  width: 100%;

  color: var(--color_grey900);
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  font-variant-numeric: tabular-nums;
`;

const ColumnHeader = styled.th`
  text-align: left;
  font-weight: 600;
  padding: 4px;
`;

const Cell = styled.td`
  display: flex;
  align-items: center;
  padding: 4px;
  border-top: 1px solid var(--color_grey100);
`;

const NameCell = styled(Cell)`
  padding-right: 16px;
  white-space: nowrap;
`;

const OverdueCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 4px;
  color: var(--color_corp_red);
`;
