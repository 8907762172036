import { NoData } from "modules/reporting/components/NoData";
import { useTranslation } from "react-i18next";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { formatNumber } from "modules/reporting/lib/formatters";
import { BacklogByAssigneeChartData } from "./types";
import { BarChart } from "modules/reporting/components/charts/BarChart";

interface BacklogByAssigneeReportChartProps {
  data?: BacklogByAssigneeChartData[];
  isFetching: boolean;
}

const reportKey = ReportKey.BacklogByAssignee;
export const BacklogByAssigneeReportChart = ({
  data,
  isFetching,
}: BacklogByAssigneeReportChartProps) => {
  const { t, i18n } = useTranslation();

  if (!isFetching && !data?.length) {
    return <NoData />;
  }

  const MAX_RECORD_COUNT = 10;
  const chartData = data ?? [];
  const displayData = [...chartData]
    .sort((a, b) => b.active - a.active)
    .slice(0, MAX_RECORD_COUNT);
  return (
    <BarChart
      data={displayData}
      isLoading={isFetching}
      dataAxisKey="assignee"
      layout="vertical"
      valueFormatter={(val) => formatNumber(val, i18n.language)}
      yAxisProps={{ width: 140 }}
      xAxisProps={{ type: "number", domain: [0, "auto"] }}
      bars={[
        {
          dataKey: "overdue",
          name: t(`reporting.${reportKey}.labels.overdue`) as string,
          fill: "var(--color_salmon)",
          stackId: "1",
        },
        {
          dataKey: "pending",
          name: t(`reporting.${reportKey}.labels.pending`) as string,
          fill: "var(--color_mint)",
          stackId: "1",
        },
        {
          dataKey: "active",
          name: t(`reporting.${reportKey}.labels.total`) as string,
          stackId: "1",
          fill: "none",
        },
      ]}
    />
  );
};
