import { useGetKPIs } from "modules/reporting/lib/useGetKPIs";
import { useTranslation } from "react-i18next";
import { ReportKey } from "modules/reporting/types/ReportKey";
import { formatNumber, formatPercent } from "modules/reporting/lib/formatters";
import { BacklogByAssigneeKPIs } from "./types";

const reportKey = ReportKey.BacklogByAssignee;
export const useBacklogByAssigneeReportKPIs = () => {
  const { data, ...rest } = useGetKPIs<BacklogByAssigneeKPIs>(reportKey);
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  return {
    ...rest,
    data: [
      {
        value: formatNumber(data?.active, language),
        label: t(`reporting.${reportKey}.kpis.active`),
      },
      {
        value: formatNumber(data?.overdue, language),
        label: t(`reporting.${reportKey}.kpis.overdue`),
      },
      {
        value: formatNumber(data?.avg_age, language),
        label: t(`reporting.${reportKey}.kpis.avg_age`),
      },
      {
        value: formatPercent(data?.overdue_percentage, language),
        label: t(`reporting.${reportKey}.kpis.overdue`),
      },
      {
        value: formatNumber(data?.reactive, language),
        label: t(`reporting.${reportKey}.kpis.reactive`),
      },
      {
        value: formatPercent(data?.reactive_percentage, language),
        label: t(`reporting.${reportKey}.kpis.reactive`),
      },
      {
        value: formatNumber(data?.preventive, language),
        label: t(`reporting.${reportKey}.kpis.preventive`),
      },
      {
        value: formatPercent(data?.preventive_percentage, language),
        label: t(`reporting.${reportKey}.kpis.preventive`),
      },
      {
        value: formatNumber(data?.not_assigned, language),
        label: t(`reporting.${reportKey}.kpis.not_assigned`),
      },
      {
        value: formatPercent(data?.not_assigned_percentage, language),
        label: t(`reporting.${reportKey}.kpis.not_assigned`),
      },
    ],
  };
};
