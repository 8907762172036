import i18n from "i18next";

// assets
const AssetsSelected = i18n.t(
  "work_order_requests.form.depended.assets.selected"
);
const AssetsWithLocation = i18n.t(
  "work_order_requests.form.depended.assets.location"
);
const AssetsWithoutLocation = i18n.t(
  "work_order_requests.form.depended.assets.empty"
);
const AssetsWithVendors = i18n.t(
  "work_order_requests.form.depended.assets.vendors"
);
const AssetsOther = i18n.t("work_order_requests.form.depended.assets.other");

const AssetsWithoutVendors = i18n.t(
  "work_order_requests.form.depended.assets.noVendors"
);

export const getAssetsOptions = (assets, params) => {
  const { location, asset, vendors = [] } = params;

  const selectedLocationId = location?.value;
  const selectedAssetId = asset?.value;
  const vendors_ids = vendors.map(({ value }) => value);

  // user picked location
  // show only selected asset, assets connected with picked location, assets without location
  if (selectedLocationId) {
    const [selected, connected, without] = assets.reduce(
      (acc, asset) => {
        const { location, id, name } = asset;

        const [selected, connected, without] = acc;

        if (id === selectedAssetId) {
          selected.options.push({ value: id, label: name });
          return acc;
        }

        if (!location?.id) {
          without.options.push({ value: id, label: name });
          return acc;
        }

        if (location.id === selectedLocationId) {
          connected.options.push({ value: id, label: name });
        }

        return acc;
      },
      [
        { label: AssetsSelected, options: [] },
        { label: AssetsWithLocation, options: [] },
        { label: AssetsWithoutLocation, options: [] },
      ]
    );

    const result = [];

    if (connected.options.length) {
      result.push(connected);
    }

    if (without.options.length) {
      result.push(without);
    }

    if (selected.options.length) {
      result.unshift(selected);
    }

    return result;
  }

  // user picked vendors
  // show only selected asset, assets connected with vendors, other assets without vendors
  if (vendors_ids.length) {
    const [selected, connected, other] = assets.reduce(
      (acc, asset) => {
        const { id, vendors, name } = asset;
        const [selected, connected, other] = acc;

        if (id === selectedAssetId) {
          selected.options.push({ value: id, label: name });
          return acc;
        }

        for (let index = 0; index < vendors.length; index++) {
          const { id: assetVendorId } = vendors[index];
          if (vendors_ids.includes(assetVendorId)) {
            connected.options.push({
              value: id,
              label: name,
            });

            return acc;
          }
        }

        if (!vendors.length) {
          other.options.push({ value: id, label: name });
        }

        return acc;
      },
      [
        { label: AssetsSelected, options: [] },
        { label: AssetsWithVendors, options: [] },
        { label: AssetsWithoutVendors, options: [] },
      ]
    );

    let result = [];
    if (connected.options.length) {
      result.push(connected);
    }

    if (selected.options.length) {
      result.unshift(selected);
    }

    if (!other.options.length && !result.length) {
      result = assets.map(({ id, name }) => ({ value: id, label: name }));
    } else {
      result.push(other);
    }

    return result;
  }

  if (selectedAssetId) {
    return assets.reduce(
      (acc, asset) => {
        const { id, name } = asset;

        const [selected, other] = acc;

        if (id === selectedAssetId) {
          selected.options.push({ value: id, label: name });
        } else {
          other.options.push({ value: id, label: name });
        }

        return acc;
      },
      [
        { label: AssetsSelected, options: [] },
        { label: AssetsOther, options: [] },
      ]
    );
  }

  return assets.map(({ id, name }) => ({ value: id, label: name }));
};

// locations
const LocationsSelected = i18n.t(
  "work_order_requests.form.depended.locations.selected"
);
const LocationWithAsset = i18n.t(
  "work_order_requests.form.depended.locations.asset"
);
const LocationsOther = i18n.t(
  "work_order_requests.form.depended.locations.other"
);
const LocationsWithVendors = i18n.t(
  "work_order_requests.form.depended.locations.vendors"
);

export const getLocationsOptions = (locations, params, field = "name") => {
  const { location, asset, vendors = [] } = params;

  const selectedLocationId = location?.value;
  const selectedAssetId = asset?.value;
  const selectedVendorIds = vendors.map(({ value }) => value);

  // user picked asset with location
  // show only location connected with asset
  if (selectedAssetId && selectedLocationId) {
    const location = locations.find(({ id }) => id === selectedLocationId);
    if (location) {
      return [
        {
          label: LocationWithAsset,
          options: [
            {
              value: location.id,
              label: location[field],
            },
          ],
        },
      ];
    }
  }

  // user picked asset with location & remove location from dropdown
  // show only location connected with asset
  if (selectedAssetId && !selectedLocationId) {
    const location = locations.find(({ assets }) => {
      const assetsIds = assets.map(({ id }) => id);
      return assetsIds.includes(selectedAssetId);
    });
    if (location) {
      return [
        {
          label: LocationWithAsset,
          options: [
            {
              value: location.id,
              label: location[field],
            },
          ],
        },
      ];
    }
  }

  // user picked vendors
  // show locations by categories: selected location, locations connected with vendors, other locations
  if (!selectedAssetId && selectedVendorIds.length) {
    const [selected, connected, other] = locations.reduce(
      (acc, location) => {
        const { id, vendors } = location;
        const [selected, connected, other] = acc;
        if (id === selectedLocationId) {
          selected.options.push({ value: id, label: location[field] });
          return acc;
        }

        for (let index = 0; index < vendors.length; index++) {
          const { id: locationVendorId } = vendors[index];
          if (selectedVendorIds.includes(locationVendorId)) {
            connected.options.push({
              value: id,
              label: location[field],
            });

            return acc;
          }
        }

        other.options.push({ value: id, label: location[field] });

        return acc;
      },
      [
        { label: LocationsSelected, options: [] },
        { label: LocationsWithVendors, options: [] },
        { label: LocationsOther, options: [] },
      ]
    );

    let result = [];

    if (connected.options.length) {
      result.push(connected);
    }

    if (selected.options.length) {
      result.unshift(selected);
    }

    if (!other.options.length && !result.length) {
      result = locations.map(({ id, [field]: name }) => ({
        value: id,
        label: name,
      }));
    } else {
      result.push(other);
    }

    return result;
  }

  if (selectedLocationId && !selectedAssetId && !selectedVendorIds.length) {
    return locations.reduce(
      (acc, location) => {
        const { id } = location;
        const [selected, other] = acc;

        if (id === selectedLocationId) {
          selected.options.push({ value: id, label: location[field] });
        } else {
          other.options.push({ value: id, label: location[field] });
        }
        return acc;
      },
      [
        { label: LocationsSelected, options: [] },
        { label: LocationsOther, options: [] },
      ]
    );
  }

  return locations.map(({ id, [field]: name }) => ({ value: id, label: name }));
};

// vendors
const VendorsSelected = i18n.t(
  "work_order_requests.form.depended.vendors.selected"
);
const VendorsWithAsset = i18n.t(
  "work_order_requests.form.depended.vendors.asset"
);
const VendorsWithLocation = i18n.t(
  "work_order_requests.form.depended.vendors.location"
);
const VendorsOther = i18n.t("work_order_requests.form.depended.vendors.other");

export const getVendorsOptions = (vendors, params) => {
  const { location, asset, vendors: selectedVendors = [] } = params;

  const selectedLocationId = location?.value;
  const selectedAssetId = asset?.value;
  const vendorIds = selectedVendors.map(({ value }) => value);

  // user picked asset
  // show vendors by categories: selected vendors, vendors connected with asset, other vendors
  if (selectedAssetId) {
    const [selected, connected, other] = vendors.reduce(
      (acc, vendor) => {
        const [selected, connected, other] = acc;
        const { id, name, assets } = vendor;

        if (vendorIds.includes(id)) {
          selected.options.push({ value: id, label: name });
          return acc;
        }

        for (let index = 0; index < assets.length; index++) {
          const { id: vendorAssetId } = assets[index];

          if (selectedAssetId === vendorAssetId) {
            connected.options.push({
              value: id,
              label: name,
            });

            return acc;
          }
        }

        other.options.push({ value: id, label: name });

        return acc;
      },
      [
        { label: VendorsSelected, options: [] },
        { label: VendorsWithAsset, options: [] },
        { label: VendorsOther, options: [] },
      ]
    );

    let result = [];

    if (connected.options.length) {
      result.push(connected);
    }

    if (selected.options.length) {
      result.push(selected);
    }

    if (!other.options.length && !result.length) {
      result = vendors.map(({ id, name }) => ({ value: id, label: name }));
    } else {
      result.push(other);
    }

    return result;
  }

  // user picked location
  // show vendors by categories: selected vendors, vendors connected with location, other vendors
  if (selectedLocationId) {
    const [selected, connected, other] = vendors.reduce(
      (acc, vendor) => {
        const [selected, connected, other] = acc;
        const { id, name, locations } = vendor;
        const locationIds = locations?.map(({ id }) => id) ?? [];

        if (vendorIds.includes(id)) {
          selected.options.push({ value: id, label: name });
          return acc;
        }

        if (locationIds.includes(selectedLocationId)) {
          connected.options.push({ value: id, label: name });
          return acc;
        }

        other.options.push({ value: id, label: name });

        return acc;
      },
      [
        { label: VendorsSelected, options: [] },
        { label: VendorsWithLocation, options: [] },
        { label: VendorsOther, options: [] },
      ]
    );

    let result = [];

    if (connected.options.length) {
      result.push(connected);
    }

    if (selected.options.length) {
      result.unshift(selected);
    }

    if (!other.options.length && !result.length) {
      result = vendors.map(({ id, name }) => ({ value: id, label: name }));
    } else {
      result.push(other);
    }

    return result;
  }

  // user picked vendors
  // show vendors by categories: selected vendors, other vendors
  if (vendorIds.length) {
    return vendors.reduce(
      (acc, vendor) => {
        const [selected, other] = acc;

        const { id, name } = vendor;

        if (vendorIds.includes(id)) {
          selected.options.push({ value: id, label: name });
        } else {
          other.options.push({ value: id, label: name });
        }
        return acc;
      },
      [
        { label: VendorsSelected, options: [] },
        { label: VendorsOther, options: [] },
      ]
    );
  }

  return vendors.map(({ id, name }) => ({ value: id, label: name }));
};

export const setAsset = (name, asset, locations, assets, form) => {
  if (asset) {
    const assetFromList = assets.find(({ id }) => id === asset.value);

    if (assetFromList && assetFromList?.location?.id) {
      const { name } = locations.find(
        ({ id }) => id === assetFromList.location.id
      );
      form.handleSetValue("location", {
        value: assetFromList.location.id,
        label: name,
      });
    } else if (asset?.location) {
      form.handleSetValue("location", asset.location);
    }

    if (asset?.vendors) {
      const currentVendorsIds = form.values.vendors.map(({ value }) => value);

      const assetVendorsIds = asset.vendors.map(({ id }) => id);

      const resultVendorsIds = new Set([
        ...currentVendorsIds,
        ...assetVendorsIds,
      ]);

      const resultVendors = Array.from(resultVendorsIds).reduce((acc, id) => {
        const vendorFromAsset = asset.vendors.find(
          ({ id: vendorId }) => vendorId === id
        );

        if (vendorFromAsset) {
          const { id, name } = vendorFromAsset;
          acc.push({ value: id, label: name });
        } else {
          const selectedVendor = form.values.vendors.find(
            ({ value }) => value === id
          );

          acc.push(selectedVendor);
        }
        return acc;
      }, []);

      form.handleSetValue("vendors", resultVendors);
    }

    const { value, label } = asset;

    form.handleSetValue(name, { value, label });
  } else {
    form.handleSetValue(name, asset);
  }
};
