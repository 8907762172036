import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { IconButton } from "shared/components/IconButton";
import { ConfirmationDialog } from "shared/components/Dialog";
import { Dropdown } from "shared/components/Dropdowns";
import { DarkDialog } from "shared/components/Dialog";
import { useSnackBar } from "shared/lib/hooks/useSnackBar";
import {
  getSettingsOptions,
  getModalImage,
  getName,
  downloadFile,
  Icon,
  useGenerateQRCodesMutation,
} from "modules/qrcodes";
import { PDFDocument } from "./PDFDocument";
import {
  Description,
  SelectedLabel,
  FieldBox,
  NewLabel,
  Image,
} from "./BulkQRCodeExport.styled";
import { Tooltip } from "react-tooltip";
import styled from "styled-components";

const DEFAULT_EXPORT_OPTION = "single";
const DENERATE_QR_CODES = "all";
const PRINT_EXISTING = "existing";

export const BulkQRCodeExport = ({
  selectedLabel,
  ids = [],
  type = "",
  module = "",
}) => {
  const { t } = useTranslation();
  const { addSnackBar } = useSnackBar();
  const [generateQrCodes] = useGenerateQRCodesMutation();

  const [itemsWithoutQRCodes, setItemsWithoutQRCodes] = useState(null);
  const [exportOption, setExportOption] = useState(DEFAULT_EXPORT_OPTION);
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [qrCodes, setQRCodes] = useState([]);

  const settingsOptions = useMemo(() => getSettingsOptions(t), [t]);
  const modalImage = useMemo(
    () => getModalImage({ qrCodes, exportOption, type }),
    [qrCodes, exportOption, type]
  );

  const exportOptionHandler = (name, value) => {
    setExportOption(value);
  };

  const openHandler = () => {
    generateHandle();
  };

  const closeHandler = () => {
    setIsOpenModal(false);
    setExportOption(DEFAULT_EXPORT_OPTION);
  };

  const proceedHandler = async () => {
    setDisabledSubmit(true);
    try {
      const doc = (
        <PDFDocument qrCodes={qrCodes} type={exportOption} module={type} />
      );
      await downloadFile(doc);
    } catch (error) {
      console.error(error);
    } finally {
      setDisabledSubmit(false);
      setIsOpenModal(false);
    }
  };

  const generateHandle = (_generate) => {
    generateQrCodes({ ids, type, ...(_generate ? { _generate } : {}) })
      .unwrap()
      .then((data) => {
        setItemsWithoutQRCodes(null);
        setQRCodes(data);
        setIsOpenModal(true);
      })
      .catch((err) => {
        if (
          err.message === "Asset has no qr codes" ||
          err.message === "Part has no qr codes" ||
          err.message === "Location has no qr codes"
        ) {
          const names = getName(err.without_qr_code);
          setItemsWithoutQRCodes(names);
        } else {
          addSnackBar({
            content: JSON.stringify(err.message),
            type: "error",
            secondaryButtonLabel: "Close",
          });
        }
      });
  };

  const validationSubmitHandler = () => {
    generateHandle(DENERATE_QR_CODES);
  };

  const validationCancelHandler = () => {
    generateHandle(PRINT_EXISTING);
  };

  const validationCloseHandler = () => {
    setItemsWithoutQRCodes(null);
  };

  return (
    <>
      <IconButton onClick={openHandler}>
        <StyledIcon data-tooltip-id="icon-tooltip-qrcode" />
        <Tooltip
          id="icon-tooltip-qrcode"
          place="bottom"
          content={t("exportQRCodes.hint")}
          opacity="1"
        />
      </IconButton>
      <DarkDialog
        isOpen={isOpenModal}
        label={t("exportQRCodes.confirmation.title", { module })}
        primaryButtonLabel={t("exportQRCodes.confirmation.confirmButtonLabel")}
        onClose={closeHandler}
        onSubmit={proceedHandler}
        disabledSubmit={disabledSubmit || qrCodes?.length <= 0}
      >
        <Description>
          <SelectedLabel>{selectedLabel}</SelectedLabel>
          <FieldBox>
            <NewLabel>{t("exportQRCodes.confirmation.exportSetting")}</NewLabel>
            <Dropdown
              name="exportOptions"
              value={exportOption}
              options={settingsOptions}
              defaultOptions={[]}
              setValue={exportOptionHandler}
              placeholder={t("startTyping")}
              withClear={false}
              withSearch={false}
              disabled={qrCodes?.length <= 0}
            />
          </FieldBox>
          <Image src={modalImage} alt="modalImage" />
        </Description>
      </DarkDialog>
      {itemsWithoutQRCodes && (
        <ConfirmationDialog
          title={t("exportQRCodes.validationConfirmation.title")}
          description={t("exportQRCodes.validationConfirmation.description", {
            names: itemsWithoutQRCodes,
          })}
          confirmButtonLabel={t(
            "exportQRCodes.validationConfirmation.primaryButton"
          )}
          cancelButtonLabel={t(
            "exportQRCodes.validationConfirmation.secondaryButton"
          )}
          onProceed={validationSubmitHandler}
          onCancel={validationCancelHandler}
          onClose={validationCloseHandler}
        />
      )}
    </>
  );
};

BulkQRCodeExport.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number),
  selectedLabel: PropTypes.string.isRequired,
  type: PropTypes.string,
  module: PropTypes.string,
};

const StyledIcon = styled(Icon)`
  outline: none;
`;
