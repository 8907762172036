import React, { useState } from "react";
import PropTypes from "prop-types";
import { skipToken } from "@reduxjs/toolkit/query";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { IconButton } from "shared/components/IconButton";
import { ConfirmationDialog } from "shared/components/Dialog";
import { useGetLocationsQuery } from "modules/locations";
import { Dropdown } from "shared/components/Dropdowns";
import Icon from "../assets/icon.svg?react";
import { Tooltip } from "react-tooltip";

const locationSearchDefault = "";
const locationIdDefault = undefined;

export const BulkLocationSetting = ({
  confirmation,
  selectedLabel,
  newLabel,
  ids = [],
  onSet,
  onSuccess,
  onError,
}) => {
  const { t } = useTranslation();

  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);

  const [locationSearch, setLocationSearch] = useState(locationSearchDefault);
  const [locationId, setLocationId] = useState(locationIdDefault);

  const { data: locations } = useGetLocationsQuery(
    isConfirmationVisible
      ? { ...(locationSearch ? { search: locationSearch } : {}) }
      : skipToken
  );

  const locationIdChangeHandler = (name, value) => {
    setLocationId(value);
  };

  const clickHandler = () => {
    setIsConfirmationVisible(true);
  };

  const proceedHandler = () => {
    if (ids.length > 0) {
      onSet({ ids, locationId })
        .unwrap()
        .then(() => {
          if (onSuccess) {
            onSuccess(
              locations?.data.find(({ id }) => id === locationId)?.name
            );
          }
        })
        .catch(() => {
          if (onError) {
            onError();
          }
        });
    }
  };

  const cancelHandler = () => {
    setIsConfirmationVisible(false);
    setLocationSearch(locationSearchDefault);
    setLocationId(locationIdDefault);
  };

  const parentOptions =
    locations?.data.map((loc) => ({
      key: loc.id,
      value: loc.name,
    })) ?? [];

  return (
    <>
      <IconButton onClick={clickHandler}>
        <StyledIcon data-tooltip-id="icon-tooltip-location" />
        <Tooltip
          id="icon-tooltip-location"
          place="bottom"
          content={t("assets.bulkActions.setLocation.hint")}
          opacity="1"
        />
      </IconButton>
      {isConfirmationVisible && (
        <ConfirmationDialog
          {...confirmation}
          description={
            <Description>
              <SelectedLabel>{selectedLabel}</SelectedLabel>
              <FieldBox>
                <NewLabel>{newLabel}</NewLabel>
                <Dropdown
                  name="location_id"
                  value={locationId}
                  options={parentOptions}
                  defaultOptions={[]}
                  setValue={locationIdChangeHandler}
                  onSearch={setLocationSearch}
                  placeholder={t("startTyping")}
                />
              </FieldBox>
            </Description>
          }
          onProceed={proceedHandler}
          onCancel={cancelHandler}
        />
      )}
    </>
  );
};

BulkLocationSetting.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number),
  selectedLabel: PropTypes.string.isRequired,
  newLabel: PropTypes.string.isRequired,
  onSet: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  confirmation: PropTypes.shape({
    title: PropTypes.string,
    confirmButtonLabel: PropTypes.string,
    cancelButtonLabel: PropTypes.string,
  }).isRequired,
};

const Description = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  padding-bottom: 32px;
  gap: 16px;
`;

const SelectedLabel = styled.span`
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  color: var(--color_grey900);
`;

const FieldBox = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
`;

const NewLabel = styled.span`
  flex-shrink: 0;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: var(--color_grey900);
`;

const StyledIcon = styled(Icon)`
  outline: none;
`;
